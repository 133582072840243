import { default as loginEMuTfubXvqMeta } from "/app/apps/nuxt/pages/auth/login.vue?macro=true";
import { default as registerBXwBpGnFpBMeta } from "/app/apps/nuxt/pages/auth/register.vue?macro=true";
import { default as reset_45password_45successwbaYrvDp4tMeta } from "/app/apps/nuxt/pages/auth/reset-password-success.vue?macro=true";
import { default as reset_45password8cRBG4P2aYMeta } from "/app/apps/nuxt/pages/auth/reset-password.vue?macro=true";
import { default as set_45new_45passwords5J2jTey7IMeta } from "/app/apps/nuxt/pages/auth/set-new-password.vue?macro=true";
import { default as cartgPofhvg29tMeta } from "/app/apps/nuxt/pages/cart.vue?macro=true";
import { default as category1xtDvr391CMeta } from "/app/apps/nuxt/pages/category.vue?macro=true";
import { default as failedSYavvRwd3gMeta } from "/app/apps/nuxt/pages/checkout/failed.vue?macro=true";
import { default as MultisafepayEQzwMp9aarMeta } from "/app/apps/nuxt/pages/checkout/gateways/Multisafepay.vue?macro=true";
import { default as Paypal2VQ3NI4eRJMeta } from "/app/apps/nuxt/pages/checkout/gateways/Paypal.vue?macro=true";
import { default as indexUEg0qLMQvEMeta } from "/app/apps/nuxt/pages/checkout/index.vue?macro=true";
import { default as CheckoutAccountta3c8aJO8rMeta } from "/app/apps/nuxt/pages/checkout/steps/CheckoutAccount.vue?macro=true";
import { default as CheckoutAddresswBLssRmegaMeta } from "/app/apps/nuxt/pages/checkout/steps/CheckoutAddress.vue?macro=true";
import { default as CheckoutDeliverycRZiJd4YPgMeta } from "/app/apps/nuxt/pages/checkout/steps/CheckoutDelivery.vue?macro=true";
import { default as CheckoutMessagej4dX5OnKngMeta } from "/app/apps/nuxt/pages/checkout/steps/CheckoutMessage.vue?macro=true";
import { default as CheckoutPaymentIA7SwCBGUPMeta } from "/app/apps/nuxt/pages/checkout/steps/CheckoutPayment.vue?macro=true";
import { default as CheckoutVouchersn1wYLJkVQoMeta } from "/app/apps/nuxt/pages/checkout/steps/CheckoutVouchers.vue?macro=true";
import { default as Step5nghbY5okXMeta } from "/app/apps/nuxt/pages/checkout/steps/Step.vue?macro=true";
import { default as successfIC9BsmepaMeta } from "/app/apps/nuxt/pages/checkout/success.vue?macro=true";
import { default as validateLLMUL3kEnEMeta } from "/app/apps/nuxt/pages/checkout/validate.vue?macro=true";
import { default as erroreRiEG6jpgaMeta } from "/app/apps/nuxt/pages/error.vue?macro=true";
import { default as home9xLMPtWrmJMeta } from "/app/apps/nuxt/pages/home.vue?macro=true";
import { default as addressespwJWgtBtUxMeta } from "/app/apps/nuxt/pages/my-account/addresses.vue?macro=true";
import { default as billing_45detailsr81oRp2KsRMeta } from "/app/apps/nuxt/pages/my-account/billing-details.vue?macro=true";
import { default as indexoytxRUqvAeMeta } from "/app/apps/nuxt/pages/my-account/index.vue?macro=true";
import { default as ordersr7usvXMGmvMeta } from "/app/apps/nuxt/pages/my-account/orders.vue?macro=true";
import { default as personal_45dataoWO9xxkGIAMeta } from "/app/apps/nuxt/pages/my-account/personal-data.vue?macro=true";
import { default as returnsVwBxoEpZAYMeta } from "/app/apps/nuxt/pages/my-account/returns.vue?macro=true";
import { default as offlineBmZQpKteraMeta } from "/app/apps/nuxt/pages/offline.vue?macro=true";
import { default as pageKwVy8mretkMeta } from "/app/apps/nuxt/pages/page.vue?macro=true";
import { default as productLJjUWELUWAMeta } from "/app/apps/nuxt/pages/product.vue?macro=true";
import { default as search2uPwETEUDhMeta } from "/app/apps/nuxt/pages/search.vue?macro=true";
export default [
  {
    name: "auth-login___en",
    path: "/en/auth/login",
    component: () => import("/app/apps/nuxt/pages/auth/login.vue")
  },
  {
    name: "auth-login___de",
    path: "/de/auth/login",
    component: () => import("/app/apps/nuxt/pages/auth/login.vue")
  },
  {
    name: "auth-login___it",
    path: "/it/auth/login",
    component: () => import("/app/apps/nuxt/pages/auth/login.vue")
  },
  {
    name: "auth-login___es",
    path: "/es/auth/login",
    component: () => import("/app/apps/nuxt/pages/auth/login.vue")
  },
  {
    name: "auth-login___fr",
    path: "/fr/auth/login",
    component: () => import("/app/apps/nuxt/pages/auth/login.vue")
  },
  {
    name: "auth-register___en",
    path: "/en/auth/register",
    component: () => import("/app/apps/nuxt/pages/auth/register.vue")
  },
  {
    name: "auth-register___de",
    path: "/de/auth/register",
    component: () => import("/app/apps/nuxt/pages/auth/register.vue")
  },
  {
    name: "auth-register___it",
    path: "/it/auth/register",
    component: () => import("/app/apps/nuxt/pages/auth/register.vue")
  },
  {
    name: "auth-register___es",
    path: "/es/auth/register",
    component: () => import("/app/apps/nuxt/pages/auth/register.vue")
  },
  {
    name: "auth-register___fr",
    path: "/fr/auth/register",
    component: () => import("/app/apps/nuxt/pages/auth/register.vue")
  },
  {
    name: "auth-reset-password-success___en",
    path: "/en/auth/reset-password-success",
    component: () => import("/app/apps/nuxt/pages/auth/reset-password-success.vue")
  },
  {
    name: "auth-reset-password-success___de",
    path: "/de/auth/reset-password-success",
    component: () => import("/app/apps/nuxt/pages/auth/reset-password-success.vue")
  },
  {
    name: "auth-reset-password-success___it",
    path: "/it/auth/reset-password-success",
    component: () => import("/app/apps/nuxt/pages/auth/reset-password-success.vue")
  },
  {
    name: "auth-reset-password-success___es",
    path: "/es/auth/reset-password-success",
    component: () => import("/app/apps/nuxt/pages/auth/reset-password-success.vue")
  },
  {
    name: "auth-reset-password-success___fr",
    path: "/fr/auth/reset-password-success",
    component: () => import("/app/apps/nuxt/pages/auth/reset-password-success.vue")
  },
  {
    name: "auth-reset-password___en",
    path: "/en/auth/reset-password",
    component: () => import("/app/apps/nuxt/pages/auth/reset-password.vue")
  },
  {
    name: "auth-reset-password___de",
    path: "/de/auth/reset-password",
    component: () => import("/app/apps/nuxt/pages/auth/reset-password.vue")
  },
  {
    name: "auth-reset-password___it",
    path: "/it/auth/reset-password",
    component: () => import("/app/apps/nuxt/pages/auth/reset-password.vue")
  },
  {
    name: "auth-reset-password___es",
    path: "/es/auth/reset-password",
    component: () => import("/app/apps/nuxt/pages/auth/reset-password.vue")
  },
  {
    name: "auth-reset-password___fr",
    path: "/fr/auth/reset-password",
    component: () => import("/app/apps/nuxt/pages/auth/reset-password.vue")
  },
  {
    name: "auth-set-new-password___en",
    path: "/en/auth/set-new-password",
    component: () => import("/app/apps/nuxt/pages/auth/set-new-password.vue")
  },
  {
    name: "auth-set-new-password___de",
    path: "/de/auth/set-new-password",
    component: () => import("/app/apps/nuxt/pages/auth/set-new-password.vue")
  },
  {
    name: "auth-set-new-password___it",
    path: "/it/auth/set-new-password",
    component: () => import("/app/apps/nuxt/pages/auth/set-new-password.vue")
  },
  {
    name: "auth-set-new-password___es",
    path: "/es/auth/set-new-password",
    component: () => import("/app/apps/nuxt/pages/auth/set-new-password.vue")
  },
  {
    name: "auth-set-new-password___fr",
    path: "/fr/auth/set-new-password",
    component: () => import("/app/apps/nuxt/pages/auth/set-new-password.vue")
  },
  {
    name: "cart___en",
    path: "/en/cart",
    component: () => import("/app/apps/nuxt/pages/cart.vue")
  },
  {
    name: "cart___de",
    path: "/de/cart",
    component: () => import("/app/apps/nuxt/pages/cart.vue")
  },
  {
    name: "cart___it",
    path: "/it/cart",
    component: () => import("/app/apps/nuxt/pages/cart.vue")
  },
  {
    name: "cart___es",
    path: "/es/cart",
    component: () => import("/app/apps/nuxt/pages/cart.vue")
  },
  {
    name: "cart___fr",
    path: "/fr/cart",
    component: () => import("/app/apps/nuxt/pages/cart.vue")
  },
  {
    name: "category___en",
    path: "/en/category",
    component: () => import("/app/apps/nuxt/pages/category.vue")
  },
  {
    name: "category___de",
    path: "/de/category",
    component: () => import("/app/apps/nuxt/pages/category.vue")
  },
  {
    name: "category___it",
    path: "/it/category",
    component: () => import("/app/apps/nuxt/pages/category.vue")
  },
  {
    name: "category___es",
    path: "/es/category",
    component: () => import("/app/apps/nuxt/pages/category.vue")
  },
  {
    name: "category___fr",
    path: "/fr/category",
    component: () => import("/app/apps/nuxt/pages/category.vue")
  },
  {
    name: "checkout-failed___en",
    path: "/en/checkout/failed",
    component: () => import("/app/apps/nuxt/pages/checkout/failed.vue")
  },
  {
    name: "checkout-failed___de",
    path: "/de/checkout/failed",
    component: () => import("/app/apps/nuxt/pages/checkout/failed.vue")
  },
  {
    name: "checkout-failed___it",
    path: "/it/checkout/failed",
    component: () => import("/app/apps/nuxt/pages/checkout/failed.vue")
  },
  {
    name: "checkout-failed___es",
    path: "/es/checkout/failed",
    component: () => import("/app/apps/nuxt/pages/checkout/failed.vue")
  },
  {
    name: "checkout-failed___fr",
    path: "/fr/checkout/failed",
    component: () => import("/app/apps/nuxt/pages/checkout/failed.vue")
  },
  {
    name: "checkout-gateways-Multisafepay___en",
    path: "/en/checkout/gateways/Multisafepay",
    component: () => import("/app/apps/nuxt/pages/checkout/gateways/Multisafepay.vue")
  },
  {
    name: "checkout-gateways-Multisafepay___de",
    path: "/de/checkout/gateways/Multisafepay",
    component: () => import("/app/apps/nuxt/pages/checkout/gateways/Multisafepay.vue")
  },
  {
    name: "checkout-gateways-Multisafepay___it",
    path: "/it/checkout/gateways/Multisafepay",
    component: () => import("/app/apps/nuxt/pages/checkout/gateways/Multisafepay.vue")
  },
  {
    name: "checkout-gateways-Multisafepay___es",
    path: "/es/checkout/gateways/Multisafepay",
    component: () => import("/app/apps/nuxt/pages/checkout/gateways/Multisafepay.vue")
  },
  {
    name: "checkout-gateways-Multisafepay___fr",
    path: "/fr/checkout/gateways/Multisafepay",
    component: () => import("/app/apps/nuxt/pages/checkout/gateways/Multisafepay.vue")
  },
  {
    name: "checkout-gateways-Paypal___en",
    path: "/en/checkout/gateways/Paypal",
    component: () => import("/app/apps/nuxt/pages/checkout/gateways/Paypal.vue")
  },
  {
    name: "checkout-gateways-Paypal___de",
    path: "/de/checkout/gateways/Paypal",
    component: () => import("/app/apps/nuxt/pages/checkout/gateways/Paypal.vue")
  },
  {
    name: "checkout-gateways-Paypal___it",
    path: "/it/checkout/gateways/Paypal",
    component: () => import("/app/apps/nuxt/pages/checkout/gateways/Paypal.vue")
  },
  {
    name: "checkout-gateways-Paypal___es",
    path: "/es/checkout/gateways/Paypal",
    component: () => import("/app/apps/nuxt/pages/checkout/gateways/Paypal.vue")
  },
  {
    name: "checkout-gateways-Paypal___fr",
    path: "/fr/checkout/gateways/Paypal",
    component: () => import("/app/apps/nuxt/pages/checkout/gateways/Paypal.vue")
  },
  {
    name: "checkout___en",
    path: "/en/checkout",
    component: () => import("/app/apps/nuxt/pages/checkout/index.vue")
  },
  {
    name: "checkout___de",
    path: "/de/checkout",
    component: () => import("/app/apps/nuxt/pages/checkout/index.vue")
  },
  {
    name: "checkout___it",
    path: "/it/checkout",
    component: () => import("/app/apps/nuxt/pages/checkout/index.vue")
  },
  {
    name: "checkout___es",
    path: "/es/checkout",
    component: () => import("/app/apps/nuxt/pages/checkout/index.vue")
  },
  {
    name: "checkout___fr",
    path: "/fr/checkout",
    component: () => import("/app/apps/nuxt/pages/checkout/index.vue")
  },
  {
    name: "checkout-steps-CheckoutAccount___en",
    path: "/en/checkout/steps/CheckoutAccount",
    component: () => import("/app/apps/nuxt/pages/checkout/steps/CheckoutAccount.vue")
  },
  {
    name: "checkout-steps-CheckoutAccount___de",
    path: "/de/checkout/steps/CheckoutAccount",
    component: () => import("/app/apps/nuxt/pages/checkout/steps/CheckoutAccount.vue")
  },
  {
    name: "checkout-steps-CheckoutAccount___it",
    path: "/it/checkout/steps/CheckoutAccount",
    component: () => import("/app/apps/nuxt/pages/checkout/steps/CheckoutAccount.vue")
  },
  {
    name: "checkout-steps-CheckoutAccount___es",
    path: "/es/checkout/steps/CheckoutAccount",
    component: () => import("/app/apps/nuxt/pages/checkout/steps/CheckoutAccount.vue")
  },
  {
    name: "checkout-steps-CheckoutAccount___fr",
    path: "/fr/checkout/steps/CheckoutAccount",
    component: () => import("/app/apps/nuxt/pages/checkout/steps/CheckoutAccount.vue")
  },
  {
    name: "checkout-steps-CheckoutAddress___en",
    path: "/en/checkout/steps/CheckoutAddress",
    component: () => import("/app/apps/nuxt/pages/checkout/steps/CheckoutAddress.vue")
  },
  {
    name: "checkout-steps-CheckoutAddress___de",
    path: "/de/checkout/steps/CheckoutAddress",
    component: () => import("/app/apps/nuxt/pages/checkout/steps/CheckoutAddress.vue")
  },
  {
    name: "checkout-steps-CheckoutAddress___it",
    path: "/it/checkout/steps/CheckoutAddress",
    component: () => import("/app/apps/nuxt/pages/checkout/steps/CheckoutAddress.vue")
  },
  {
    name: "checkout-steps-CheckoutAddress___es",
    path: "/es/checkout/steps/CheckoutAddress",
    component: () => import("/app/apps/nuxt/pages/checkout/steps/CheckoutAddress.vue")
  },
  {
    name: "checkout-steps-CheckoutAddress___fr",
    path: "/fr/checkout/steps/CheckoutAddress",
    component: () => import("/app/apps/nuxt/pages/checkout/steps/CheckoutAddress.vue")
  },
  {
    name: "checkout-steps-CheckoutDelivery___en",
    path: "/en/checkout/steps/CheckoutDelivery",
    component: () => import("/app/apps/nuxt/pages/checkout/steps/CheckoutDelivery.vue")
  },
  {
    name: "checkout-steps-CheckoutDelivery___de",
    path: "/de/checkout/steps/CheckoutDelivery",
    component: () => import("/app/apps/nuxt/pages/checkout/steps/CheckoutDelivery.vue")
  },
  {
    name: "checkout-steps-CheckoutDelivery___it",
    path: "/it/checkout/steps/CheckoutDelivery",
    component: () => import("/app/apps/nuxt/pages/checkout/steps/CheckoutDelivery.vue")
  },
  {
    name: "checkout-steps-CheckoutDelivery___es",
    path: "/es/checkout/steps/CheckoutDelivery",
    component: () => import("/app/apps/nuxt/pages/checkout/steps/CheckoutDelivery.vue")
  },
  {
    name: "checkout-steps-CheckoutDelivery___fr",
    path: "/fr/checkout/steps/CheckoutDelivery",
    component: () => import("/app/apps/nuxt/pages/checkout/steps/CheckoutDelivery.vue")
  },
  {
    name: "checkout-steps-CheckoutMessage___en",
    path: "/en/checkout/steps/CheckoutMessage",
    component: () => import("/app/apps/nuxt/pages/checkout/steps/CheckoutMessage.vue")
  },
  {
    name: "checkout-steps-CheckoutMessage___de",
    path: "/de/checkout/steps/CheckoutMessage",
    component: () => import("/app/apps/nuxt/pages/checkout/steps/CheckoutMessage.vue")
  },
  {
    name: "checkout-steps-CheckoutMessage___it",
    path: "/it/checkout/steps/CheckoutMessage",
    component: () => import("/app/apps/nuxt/pages/checkout/steps/CheckoutMessage.vue")
  },
  {
    name: "checkout-steps-CheckoutMessage___es",
    path: "/es/checkout/steps/CheckoutMessage",
    component: () => import("/app/apps/nuxt/pages/checkout/steps/CheckoutMessage.vue")
  },
  {
    name: "checkout-steps-CheckoutMessage___fr",
    path: "/fr/checkout/steps/CheckoutMessage",
    component: () => import("/app/apps/nuxt/pages/checkout/steps/CheckoutMessage.vue")
  },
  {
    name: "checkout-steps-CheckoutPayment___en",
    path: "/en/checkout/steps/CheckoutPayment",
    component: () => import("/app/apps/nuxt/pages/checkout/steps/CheckoutPayment.vue")
  },
  {
    name: "checkout-steps-CheckoutPayment___de",
    path: "/de/checkout/steps/CheckoutPayment",
    component: () => import("/app/apps/nuxt/pages/checkout/steps/CheckoutPayment.vue")
  },
  {
    name: "checkout-steps-CheckoutPayment___it",
    path: "/it/checkout/steps/CheckoutPayment",
    component: () => import("/app/apps/nuxt/pages/checkout/steps/CheckoutPayment.vue")
  },
  {
    name: "checkout-steps-CheckoutPayment___es",
    path: "/es/checkout/steps/CheckoutPayment",
    component: () => import("/app/apps/nuxt/pages/checkout/steps/CheckoutPayment.vue")
  },
  {
    name: "checkout-steps-CheckoutPayment___fr",
    path: "/fr/checkout/steps/CheckoutPayment",
    component: () => import("/app/apps/nuxt/pages/checkout/steps/CheckoutPayment.vue")
  },
  {
    name: "checkout-steps-CheckoutVouchers___en",
    path: "/en/checkout/steps/CheckoutVouchers",
    component: () => import("/app/apps/nuxt/pages/checkout/steps/CheckoutVouchers.vue")
  },
  {
    name: "checkout-steps-CheckoutVouchers___de",
    path: "/de/checkout/steps/CheckoutVouchers",
    component: () => import("/app/apps/nuxt/pages/checkout/steps/CheckoutVouchers.vue")
  },
  {
    name: "checkout-steps-CheckoutVouchers___it",
    path: "/it/checkout/steps/CheckoutVouchers",
    component: () => import("/app/apps/nuxt/pages/checkout/steps/CheckoutVouchers.vue")
  },
  {
    name: "checkout-steps-CheckoutVouchers___es",
    path: "/es/checkout/steps/CheckoutVouchers",
    component: () => import("/app/apps/nuxt/pages/checkout/steps/CheckoutVouchers.vue")
  },
  {
    name: "checkout-steps-CheckoutVouchers___fr",
    path: "/fr/checkout/steps/CheckoutVouchers",
    component: () => import("/app/apps/nuxt/pages/checkout/steps/CheckoutVouchers.vue")
  },
  {
    name: "checkout-steps-Step___en",
    path: "/en/checkout/steps/Step",
    component: () => import("/app/apps/nuxt/pages/checkout/steps/Step.vue")
  },
  {
    name: "checkout-steps-Step___de",
    path: "/de/checkout/steps/Step",
    component: () => import("/app/apps/nuxt/pages/checkout/steps/Step.vue")
  },
  {
    name: "checkout-steps-Step___it",
    path: "/it/checkout/steps/Step",
    component: () => import("/app/apps/nuxt/pages/checkout/steps/Step.vue")
  },
  {
    name: "checkout-steps-Step___es",
    path: "/es/checkout/steps/Step",
    component: () => import("/app/apps/nuxt/pages/checkout/steps/Step.vue")
  },
  {
    name: "checkout-steps-Step___fr",
    path: "/fr/checkout/steps/Step",
    component: () => import("/app/apps/nuxt/pages/checkout/steps/Step.vue")
  },
  {
    name: "checkout-success___en",
    path: "/en/checkout/success",
    component: () => import("/app/apps/nuxt/pages/checkout/success.vue")
  },
  {
    name: "checkout-success___de",
    path: "/de/checkout/success",
    component: () => import("/app/apps/nuxt/pages/checkout/success.vue")
  },
  {
    name: "checkout-success___it",
    path: "/it/checkout/success",
    component: () => import("/app/apps/nuxt/pages/checkout/success.vue")
  },
  {
    name: "checkout-success___es",
    path: "/es/checkout/success",
    component: () => import("/app/apps/nuxt/pages/checkout/success.vue")
  },
  {
    name: "checkout-success___fr",
    path: "/fr/checkout/success",
    component: () => import("/app/apps/nuxt/pages/checkout/success.vue")
  },
  {
    name: "checkout-validate___en",
    path: "/en/checkout/validate",
    component: () => import("/app/apps/nuxt/pages/checkout/validate.vue")
  },
  {
    name: "checkout-validate___de",
    path: "/de/checkout/validate",
    component: () => import("/app/apps/nuxt/pages/checkout/validate.vue")
  },
  {
    name: "checkout-validate___it",
    path: "/it/checkout/validate",
    component: () => import("/app/apps/nuxt/pages/checkout/validate.vue")
  },
  {
    name: "checkout-validate___es",
    path: "/es/checkout/validate",
    component: () => import("/app/apps/nuxt/pages/checkout/validate.vue")
  },
  {
    name: "checkout-validate___fr",
    path: "/fr/checkout/validate",
    component: () => import("/app/apps/nuxt/pages/checkout/validate.vue")
  },
  {
    name: "error___en",
    path: "/en/error",
    component: () => import("/app/apps/nuxt/pages/error.vue")
  },
  {
    name: "error___de",
    path: "/de/error",
    component: () => import("/app/apps/nuxt/pages/error.vue")
  },
  {
    name: "error___it",
    path: "/it/error",
    component: () => import("/app/apps/nuxt/pages/error.vue")
  },
  {
    name: "error___es",
    path: "/es/error",
    component: () => import("/app/apps/nuxt/pages/error.vue")
  },
  {
    name: "error___fr",
    path: "/fr/error",
    component: () => import("/app/apps/nuxt/pages/error.vue")
  },
  {
    name: "home___en",
    path: "/en/home",
    component: () => import("/app/apps/nuxt/pages/home.vue")
  },
  {
    name: "home___de",
    path: "/de/home",
    component: () => import("/app/apps/nuxt/pages/home.vue")
  },
  {
    name: "home___it",
    path: "/it/home",
    component: () => import("/app/apps/nuxt/pages/home.vue")
  },
  {
    name: "home___es",
    path: "/es/home",
    component: () => import("/app/apps/nuxt/pages/home.vue")
  },
  {
    name: "home___fr",
    path: "/fr/home",
    component: () => import("/app/apps/nuxt/pages/home.vue")
  },
  {
    name: "my-account-addresses___en",
    path: "/en/my-account/addresses",
    component: () => import("/app/apps/nuxt/pages/my-account/addresses.vue")
  },
  {
    name: "my-account-addresses___de",
    path: "/de/my-account/addresses",
    component: () => import("/app/apps/nuxt/pages/my-account/addresses.vue")
  },
  {
    name: "my-account-addresses___it",
    path: "/it/my-account/addresses",
    component: () => import("/app/apps/nuxt/pages/my-account/addresses.vue")
  },
  {
    name: "my-account-addresses___es",
    path: "/es/my-account/addresses",
    component: () => import("/app/apps/nuxt/pages/my-account/addresses.vue")
  },
  {
    name: "my-account-addresses___fr",
    path: "/fr/my-account/addresses",
    component: () => import("/app/apps/nuxt/pages/my-account/addresses.vue")
  },
  {
    name: "my-account-billing-details___en",
    path: "/en/my-account/billing-details",
    meta: billing_45detailsr81oRp2KsRMeta || {},
    component: () => import("/app/apps/nuxt/pages/my-account/billing-details.vue")
  },
  {
    name: "my-account-billing-details___de",
    path: "/de/my-account/billing-details",
    meta: billing_45detailsr81oRp2KsRMeta || {},
    component: () => import("/app/apps/nuxt/pages/my-account/billing-details.vue")
  },
  {
    name: "my-account-billing-details___it",
    path: "/it/my-account/billing-details",
    meta: billing_45detailsr81oRp2KsRMeta || {},
    component: () => import("/app/apps/nuxt/pages/my-account/billing-details.vue")
  },
  {
    name: "my-account-billing-details___es",
    path: "/es/my-account/billing-details",
    meta: billing_45detailsr81oRp2KsRMeta || {},
    component: () => import("/app/apps/nuxt/pages/my-account/billing-details.vue")
  },
  {
    name: "my-account-billing-details___fr",
    path: "/fr/my-account/billing-details",
    meta: billing_45detailsr81oRp2KsRMeta || {},
    component: () => import("/app/apps/nuxt/pages/my-account/billing-details.vue")
  },
  {
    name: "my-account___en",
    path: "/en/my-account",
    component: () => import("/app/apps/nuxt/pages/my-account/index.vue")
  },
  {
    name: "my-account___de",
    path: "/de/my-account",
    component: () => import("/app/apps/nuxt/pages/my-account/index.vue")
  },
  {
    name: "my-account___it",
    path: "/it/my-account",
    component: () => import("/app/apps/nuxt/pages/my-account/index.vue")
  },
  {
    name: "my-account___es",
    path: "/es/my-account",
    component: () => import("/app/apps/nuxt/pages/my-account/index.vue")
  },
  {
    name: "my-account___fr",
    path: "/fr/my-account",
    component: () => import("/app/apps/nuxt/pages/my-account/index.vue")
  },
  {
    name: "my-account-orders___en",
    path: "/en/my-account/orders",
    component: () => import("/app/apps/nuxt/pages/my-account/orders.vue")
  },
  {
    name: "my-account-orders___de",
    path: "/de/my-account/orders",
    component: () => import("/app/apps/nuxt/pages/my-account/orders.vue")
  },
  {
    name: "my-account-orders___it",
    path: "/it/my-account/orders",
    component: () => import("/app/apps/nuxt/pages/my-account/orders.vue")
  },
  {
    name: "my-account-orders___es",
    path: "/es/my-account/orders",
    component: () => import("/app/apps/nuxt/pages/my-account/orders.vue")
  },
  {
    name: "my-account-orders___fr",
    path: "/fr/my-account/orders",
    component: () => import("/app/apps/nuxt/pages/my-account/orders.vue")
  },
  {
    name: "my-account-personal-data___en",
    path: "/en/my-account/personal-data",
    meta: personal_45dataoWO9xxkGIAMeta || {},
    component: () => import("/app/apps/nuxt/pages/my-account/personal-data.vue")
  },
  {
    name: "my-account-personal-data___de",
    path: "/de/my-account/personal-data",
    meta: personal_45dataoWO9xxkGIAMeta || {},
    component: () => import("/app/apps/nuxt/pages/my-account/personal-data.vue")
  },
  {
    name: "my-account-personal-data___it",
    path: "/it/my-account/personal-data",
    meta: personal_45dataoWO9xxkGIAMeta || {},
    component: () => import("/app/apps/nuxt/pages/my-account/personal-data.vue")
  },
  {
    name: "my-account-personal-data___es",
    path: "/es/my-account/personal-data",
    meta: personal_45dataoWO9xxkGIAMeta || {},
    component: () => import("/app/apps/nuxt/pages/my-account/personal-data.vue")
  },
  {
    name: "my-account-personal-data___fr",
    path: "/fr/my-account/personal-data",
    meta: personal_45dataoWO9xxkGIAMeta || {},
    component: () => import("/app/apps/nuxt/pages/my-account/personal-data.vue")
  },
  {
    name: "my-account-returns___en",
    path: "/en/my-account/returns",
    meta: returnsVwBxoEpZAYMeta || {},
    component: () => import("/app/apps/nuxt/pages/my-account/returns.vue")
  },
  {
    name: "my-account-returns___de",
    path: "/de/my-account/returns",
    meta: returnsVwBxoEpZAYMeta || {},
    component: () => import("/app/apps/nuxt/pages/my-account/returns.vue")
  },
  {
    name: "my-account-returns___it",
    path: "/it/my-account/returns",
    meta: returnsVwBxoEpZAYMeta || {},
    component: () => import("/app/apps/nuxt/pages/my-account/returns.vue")
  },
  {
    name: "my-account-returns___es",
    path: "/es/my-account/returns",
    meta: returnsVwBxoEpZAYMeta || {},
    component: () => import("/app/apps/nuxt/pages/my-account/returns.vue")
  },
  {
    name: "my-account-returns___fr",
    path: "/fr/my-account/returns",
    meta: returnsVwBxoEpZAYMeta || {},
    component: () => import("/app/apps/nuxt/pages/my-account/returns.vue")
  },
  {
    name: "offline___en",
    path: "/en/offline",
    component: () => import("/app/apps/nuxt/pages/offline.vue")
  },
  {
    name: "offline___de",
    path: "/de/offline",
    component: () => import("/app/apps/nuxt/pages/offline.vue")
  },
  {
    name: "offline___it",
    path: "/it/offline",
    component: () => import("/app/apps/nuxt/pages/offline.vue")
  },
  {
    name: "offline___es",
    path: "/es/offline",
    component: () => import("/app/apps/nuxt/pages/offline.vue")
  },
  {
    name: "offline___fr",
    path: "/fr/offline",
    component: () => import("/app/apps/nuxt/pages/offline.vue")
  },
  {
    name: "page___en",
    path: "/en/page",
    component: () => import("/app/apps/nuxt/pages/page.vue")
  },
  {
    name: "page___de",
    path: "/de/page",
    component: () => import("/app/apps/nuxt/pages/page.vue")
  },
  {
    name: "page___it",
    path: "/it/page",
    component: () => import("/app/apps/nuxt/pages/page.vue")
  },
  {
    name: "page___es",
    path: "/es/page",
    component: () => import("/app/apps/nuxt/pages/page.vue")
  },
  {
    name: "page___fr",
    path: "/fr/page",
    component: () => import("/app/apps/nuxt/pages/page.vue")
  },
  {
    name: "product___en",
    path: "/en/product",
    component: () => import("/app/apps/nuxt/pages/product.vue")
  },
  {
    name: "product___de",
    path: "/de/product",
    component: () => import("/app/apps/nuxt/pages/product.vue")
  },
  {
    name: "product___it",
    path: "/it/product",
    component: () => import("/app/apps/nuxt/pages/product.vue")
  },
  {
    name: "product___es",
    path: "/es/product",
    component: () => import("/app/apps/nuxt/pages/product.vue")
  },
  {
    name: "product___fr",
    path: "/fr/product",
    component: () => import("/app/apps/nuxt/pages/product.vue")
  },
  {
    name: "search___en",
    path: "/en/search",
    component: () => import("/app/apps/nuxt/pages/search.vue")
  },
  {
    name: "search___de",
    path: "/de/search",
    component: () => import("/app/apps/nuxt/pages/search.vue")
  },
  {
    name: "search___it",
    path: "/it/search",
    component: () => import("/app/apps/nuxt/pages/search.vue")
  },
  {
    name: "search___es",
    path: "/es/search",
    component: () => import("/app/apps/nuxt/pages/search.vue")
  },
  {
    name: "search___fr",
    path: "/fr/search",
    component: () => import("/app/apps/nuxt/pages/search.vue")
  }
]