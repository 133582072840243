import routerOptions0 from "/app/node_modules/.pnpm/nuxt@3.13.2_@types+node@20.17.1_eslint@8.57.1_rollup@4.24.2_typescript@5.6.3_vite@5.4.10_vue-tsc@2.1.8/node_modules/nuxt/dist/pages/runtime/router.options";
import routerOptions1 from "/app/apps/nuxt/app/router.options.ts";
const configRouterOptions = {
  hashMode: false,
  scrollBehaviorType: "auto"
}
export default {
...configRouterOptions,
...routerOptions0,
...routerOptions1,
}