import revive_payload_client_yzWRL28N22 from "/app/node_modules/.pnpm/nuxt@3.13.2_@types+node@20.17.1_eslint@8.57.1_rollup@4.24.2_typescript@5.6.3_vite@5.4.10_vue-tsc@2.1.8/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_cQJLKL3I16 from "/app/node_modules/.pnpm/nuxt@3.13.2_@types+node@20.17.1_eslint@8.57.1_rollup@4.24.2_typescript@5.6.3_vite@5.4.10_vue-tsc@2.1.8/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_6sO8y3IaTY from "/app/node_modules/.pnpm/nuxt@3.13.2_@types+node@20.17.1_eslint@8.57.1_rollup@4.24.2_typescript@5.6.3_vite@5.4.10_vue-tsc@2.1.8/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_WHG3z1vi37 from "/app/node_modules/.pnpm/nuxt-site-config@2.2.18_rollup@4.24.2_vite@5.4.10_vue@3.5.12/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.js";
import payload_client_oMckyyXJ9b from "/app/node_modules/.pnpm/nuxt@3.13.2_@types+node@20.17.1_eslint@8.57.1_rollup@4.24.2_typescript@5.6.3_vite@5.4.10_vue-tsc@2.1.8/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_zuagRLUe1H from "/app/node_modules/.pnpm/nuxt@3.13.2_@types+node@20.17.1_eslint@8.57.1_rollup@4.24.2_typescript@5.6.3_vite@5.4.10_vue-tsc@2.1.8/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_wXPgrZp3Y0 from "/app/node_modules/.pnpm/nuxt@3.13.2_@types+node@20.17.1_eslint@8.57.1_rollup@4.24.2_typescript@5.6.3_vite@5.4.10_vue-tsc@2.1.8/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_MiKyQgt2JY from "/app/node_modules/.pnpm/nuxt@3.13.2_@types+node@20.17.1_eslint@8.57.1_rollup@4.24.2_typescript@5.6.3_vite@5.4.10_vue-tsc@2.1.8/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_S9nHSbX6vv from "/app/node_modules/.pnpm/@pinia+nuxt@0.5.5_rollup@4.24.2_typescript@5.6.3_vue@3.5.12/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/app/apps/nuxt/.nuxt/components.plugin.mjs";
import prefetch_client_bi4tvjl9rP from "/app/node_modules/.pnpm/nuxt@3.13.2_@types+node@20.17.1_eslint@8.57.1_rollup@4.24.2_typescript@5.6.3_vite@5.4.10_vue-tsc@2.1.8/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_gK7tOU7sHz from "/app/node_modules/.pnpm/@zadigetvoltaire+nuxt-gtm@0.0.13_nuxt@3.13.2_rollup@4.24.2_vue@3.5.12/node_modules/@zadigetvoltaire/nuxt-gtm/dist/runtime/plugin.mjs";
import formkitPlugin_pZqjah0RUG from "/app/apps/nuxt/.nuxt/formkitPlugin.mjs";
import switch_locale_path_ssr_Ivo8tJrxiu from "/app/node_modules/.pnpm/@nuxtjs+i18n@8.5.5_rollup@4.24.2_vue@3.5.12/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.js";
import i18n_ZthepIxxHv from "/app/node_modules/.pnpm/@nuxtjs+i18n@8.5.5_rollup@4.24.2_vue@3.5.12/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.js";
export default [
  revive_payload_client_yzWRL28N22,
  unhead_cQJLKL3I16,
  router_6sO8y3IaTY,
  _0_siteConfig_WHG3z1vi37,
  payload_client_oMckyyXJ9b,
  navigation_repaint_client_zuagRLUe1H,
  check_outdated_build_client_wXPgrZp3Y0,
  chunk_reload_client_MiKyQgt2JY,
  plugin_vue3_S9nHSbX6vv,
  components_plugin_KR1HBZs4kY,
  prefetch_client_bi4tvjl9rP,
  plugin_gK7tOU7sHz,
  formkitPlugin_pZqjah0RUG,
  switch_locale_path_ssr_Ivo8tJrxiu,
  i18n_ZthepIxxHv
]